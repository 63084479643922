<template>
    <s-container>
        <head-search class='pb20'></head-search>
        <div>
            <my-search-table :columns="columns">
                
            </my-search-table>
        </div>
    </s-container>
</template>

<script>
    import { HeadSearch, MySearchTable} from './components'
    let columns = [
    { title: '序号',
        dataIndex: 'serialnum'
    },
    {
        title: '检索式',
        dataIndex: 'search'
    },
    {
        title: '结果',
        dataIndex: 'result'
    },
    {
        title: '检索时间',
        dataIndex: 'time'
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
    },
    ];
    export default {
        name:'SearchHistory',
        data() {
            return {
                columns
            }
        },
        components:{
            HeadSearch,
            MySearchTable
        }
    }
</script>

<style scoped>

</style>